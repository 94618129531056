import { makeAutoObservable } from "mobx";
import Page from "../types/Page";
import Video from "types/Video";
import CardNewsSlideGroup from "types/CardNewsSlideGroup";
import ChannelService from "ChannelService";

class PageStore {
  page: Page | null = null;
  // isLike: boolean | null = null;
  activeVideo: Video | null = null;
  activeCardNewsSlideGroup: CardNewsSlideGroup | null = null;

  constructor() {
    makeAutoObservable(this);
    
  }

  reset() {
    this.page = null;
    // this.isLike = null;
    this.activeVideo = null;
    this.activeCardNewsSlideGroup = null;
  }

  // setIsLike(isLike: boolean) {
  //   this.isLike = isLike;
  // }

  setActiveVideo(activeVideo: Video) {
    this.activeVideo = activeVideo;
  }

  setActiveCardNewsSlideGroup(currentCardNewsSlideGroup: CardNewsSlideGroup) {
    this.activeCardNewsSlideGroup = currentCardNewsSlideGroup;
  }

  async setPage(page: Page) {
    this.page = page;

    if (typeof window !== 'undefined' && ChannelService) { 
      ChannelService.boot({
        pluginKey: this.page.channelIOCode
      });

      ChannelService.hideChannelButton();
    }

    if (this.activeVideo === null) {
      this.setActiveVideo(this.page.videos[0]);
    }

    if (this.activeCardNewsSlideGroup === null) {
      this.setActiveCardNewsSlideGroup(this.page.cardNewsSlideGroups[0]);
    }

    // if (this.isLike === null && typeof window !== 'undefined') {
    //   this.setIsLike(false);

    //   request<LikeResponse>({url: `page/${this.page.id}/like?노쇼=true`, method: 'POST', disableErrorAlert: true}, LikeResponse)
    //     .then(r => {
    //       this.setIsLike(r.like);
    //     })
    //     .catch(() => {
    //     });
    // }
  }

  // setLikes(likes: number) {
  //   if (!this.page) return;

  //   this.setPage(
  //     update(this.page, {
  //       likes: {$set: likes}
  //     })
  //   );
  // }

  // doLike() {
  //   if (!this.page) return;

  //   LoadingStore.setIsLoading(true);

  //   request<LikeResponse>({url: `page/${this.page.id}/like`, method: 'POST'}, LikeResponse)
  //     .then(r => {
  //       if (!this.page) return;
        
  //       this.setPage(
  //         update(this.page, {
  //           likes: {$set: r.likes}
  //         })
  //       );

  //       this.setIsLike(r.like);
  //     })
  //     .catch(() => {})
  //     .then(() => {
  //       LoadingStore.setIsLoading(false);
  //     });
  // }
}

export default new PageStore();