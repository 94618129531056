import { GetServerSideProps, NextPage } from "next";
import React, { useRef, useState } from "react";
import request from "../../request";
import Page from "../../types/Page";
import { plainToClass } from "class-transformer";
import Head from "next/head";
import { observer } from "mobx-react-lite";
import PageStore from "../../stores/PageStore";
import { useRouter } from "next/router";
import Image from 'next/image';
import dynamic from "next/dynamic";
import Typography from "@mui/material/Typography";
import PageMenu from 'types/PageMenu'

// const 첫번째페이지 = dynamic(() => import('components/첫번째페이지'));
const 두번째페이지 = dynamic(() => import('components/두번째페이지'));

interface Props {
  data: {
    page: any;
    host: string;
    userAgent: string;
  }
}

const Test: NextPage<Props> = ({data}) => {
  const {host, userAgent} = data;
  const [currentPageMenu, setCurrentPageMenu] = useState<PageMenu>(PageMenu.홈);
  const router = useRouter();
  const isHydrated = useRef({server: false, client: false});
  const [isCanPlay, setIsCanPlay] = useState(false);

  if (
    (typeof window === 'undefined' && !isHydrated.current.server)
    || (typeof window !== 'undefined' && !isHydrated.current.client)
  ) {
    PageStore.reset();

    PageStore.setPage(plainToClass(Page, data.page));

    if (typeof window === 'undefined') {
      isHydrated.current.server = true;
    } else {
      isHydrated.current.client = true;
    }
  }

  return (
    <>
    {PageStore.page && (
      <>
      <Head>
        <title>{PageStore.page.personName}</title>
        <meta property="og:title" content={PageStore.page.personName} />
        <meta property="og:description" content={PageStore.page.shareText} />
        <meta name="description" content={PageStore.page.shareText} />
        <meta property="og:image" content={PageStore.page.titleImageURL} />
        <meta property="og:url" content={`https://${host}${router.asPath.replace('?tbp=1', '')}`} />
        <meta property="og:image:width" content="1000" />
        <meta property="og:image:height" content="600" />
        <link rel="apple-touch-icon" sizes="192x192" href={PageStore.page.titleImageURL} />
        <link rel="icon" type="image/webp" href={PageStore.page.titleImageURL} />
        <link rel="shortcut icon" type="image/webp" href={PageStore.page.titleImageURL} />
        <link rel="manifest" href={`data:application/manifest+json,{"name": "${PageStore.page.personName}", "icons": [{"src": "${PageStore.page.titleImageURL}", "sizes": "600x600", "type": "image/png"}], "display": "standalone", "scope": "https://${host}${router.asPath.replace('?tbp=1', '')}", "start_url": "https://${host}${router.asPath.replace('?tbp=1', '')}"}`} />
        
        <style>
          {`
            #ch-plugin-core > div, #ch-plugin-script {
              z-index: 3699999 !important;
            }
          `}
        </style>
      </Head>

      {/* <첫번째페이지 data={data} currentPageMenu={currentPageMenu} setCurrentPageMenu={setCurrentPageMenu} isCanPlay={isCanPlay} setIsCanPlay={setIsCanPlay} /> */}
      <두번째페이지 currentPageMenu={currentPageMenu} />

      <div className={`z-[364667] bg-[#333333] fixed bottom-0 left-0 right-0 mx-auto w-full flex items-center justify-center transition-all duration-500 flex-col gap-5`}>
        <div className="flex gap-2 py-4 w-full items-center justify-center max-w-[444px] mb-[env(safe-area-inset-bottom)]">
          {(Object.keys(PageMenu) as Array<PageMenu>).map(menu => (
            <div key={menu} className={`px-3 w-[109px] text-center py-[7px] rounded-full transition-all duration-400 ${currentPageMenu == menu && 'bg-white shadow-md'}`} onClick={() => setCurrentPageMenu(menu)}>
              <h6 className={`${currentPageMenu == menu ? 'text-[#003668] font-black' : 'text-[#bbb]'}`}>{menu}</h6>
            </div>
          ))}
        </div>
      </div>

      <div className={`h-[62px] z-[364667] top-0 left-0 right-0 mx-auto flex gap-1 px-4 items-center bg-white transition-all relative max-w-[444px] w-full duration-500 shadow`}>
        <div className="rounded-full flex-[0_0_auto] overflow-hidden relative w-[40px] h-[40px]">
          <Image src={PageStore.page.companyImageURL} layout="fixed" width="40px" height="40px" objectFit="contain" />
        </div>

        <div className="flex flex-col min-w-0 gap-1">
          <h6 className="text-sm font-bold leading-none nowrap">{PageStore.page.companyName}</h6>

          <Typography variant="caption" className="leading-none text-[#777]" noWrap>{PageStore.page.companyComment}</Typography>
        </div>
      </div>
      </>
    )}
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async ctx => {
  const page = await request({url: `page/${ctx.query.name}`, method: 'GET'}, null)
    .then(r => r)
    .catch(e => {
      if (e.response.status === 404) {
        return {notFound: true};
      } else {
        throw e;
      }
    });

  if (page.hasOwnProperty('notFound')) {
    return {notFound: true};
  }
  
  return {props: {data: {page, host: ctx.req.headers.host, userAgent: ctx.req.headers["user-agent"]?.toLowerCase()}}};
};

export default observer(Test);