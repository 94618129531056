
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/page/[name]",
      function () {
        return require("private-next-pages/page/[name].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/page/[name]"])
      });
    }
  