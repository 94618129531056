import CardNewsSlideGroup from "./CardNewsSlideGroup";
import CustomButton from "./CustomButton";
import Notice from "./Notice";
import Owner from "./Owner";
import Party from "./Party";
import SNS from "./SNS";
import Theme from "./Theme";
import Video from "./Video";

class Page {
  createdAt: string;
  updatedAt: string;
  id?: string;
  name: string;
  cardNewsSlideGroups: CardNewsSlideGroup[];
  customButtons: CustomButton[];
  domain: string;
  videos: Video[];
  notices: Notice[];
  views: number;
  titleImageURL: string;
  profileImageURL: string;
  companyImageURL: string;
  race: string;
  comment: string;
  companyComment: string;
  companyName: string;
  personName: string;
  channelIOCode: string;
  theme: Theme;
  profileImageTransparent: boolean;
  phone: string;
  anotherLink: string;
  youtubeLink: string;
  instagramLink: string;
  facebookLink: string;
  twitterLink: string;
  kakaoChannelLink: string;
  baeminLink: string;
  showPhoneCallButton: boolean;
  showPhoneSMSButton: boolean;
  showLinkButton: boolean;
  showYoutubeLinkButton: boolean;
  showInstagramLinkButton: boolean;
  showFacebookLinkButton: boolean;
  showTwitterLinkButton: boolean;
  showKakaoChannelLinkButton: boolean;
  showBaeminLinkButton: boolean;
  showQrcodeButton: boolean;
  showShareButton: boolean;

  get domainAuto() {
    if (this.domain) {
      return this.domain;
    }

    return window.location.href;
  }

  get shareText() {
    return `${this.race} \n${this.companyName} \n${this.companyComment}`;
  }
}

export default Page;